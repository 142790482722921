import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      isAuthenticated: false
    },
    component: Home
  },
  {
    path: '/msp/muc',
    name: 'MUC',
    meta: {
      isAuthenticated: true,
      role: 'muc'
    },
    component: () => import('../views/muc/Muc.vue')
  },
  {
    path: '/msp/muc/matrix',
    name: 'Matrix',
    meta: {
      isAuthenticated: true,
      role: 'muc'
    },
    component: () => import('../views/muc/Matrix.vue')
  },
  {
    path: '/msp/mangrove',
    name: 'Mangrove',
    meta: {
      isAuthenticated: true,
      role: 'mangrove'
    },
    component: () => import('../views/mangrove/Mangrove.vue')
  },
  {
    path: '/msp/oilspill',
    name: 'Oilspill',
    meta: {
      isAuthenticated: true,
      role: 'oilspill'
    },
    component: () => import('../views/oilspill/Oilspill.vue')
  },
  {
    path: '/msp/oilspill-graph',
    name: 'OilspillGraph',
    meta: {
      isAuthenticated: true,
      role: 'oilspill'
    },
    component: () => import('../views/oilspill/OilspillGraph.vue')
  },
  {
    path: '/admin/muc-conflict',
    name: 'MucConflict',
    meta: {
      isAuthenticated: true,
      role: 'manager'
    },
    component: () => import('../views/admin/MucConflict.vue')
  },
  {
    path: '/admin/muc-conflict-place',
    name: 'MucConflictPlace',
    meta: {
      isAuthenticated: true,
      role: 'manager'
    },
    component: () => import('../views/admin/MucConflictPlace.vue')
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound,
    meta: {
      isAuthenticated: false
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (to.meta.isAuthenticated) {
    // Get the actual url of the app, it's needed for Keycloak
    const basePath = window.location.toString()

    if (!Vue.$keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      Vue.$keycloak.login({ redirectUri: basePath })
      // console.log('is not authenticated')
    } else if (Vue.$keycloak.realmAccess.roles.includes(to.meta.role)) {
      // The user was authenticated, and has the app role
      // console.log('authenticated')
      Vue.$keycloak.updateToken(70)
        .then(() => {
          next()
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      // The user was authenticated, but did not have the correct role
      // Redirect to an error page
      next({ name: 'Home' })
    }
  } else {
    // This page did not require authentication
    next()
  }
})
export default router
